<template>
  <div class="map-wrap">
    <div v-show="adresssearch" class="map-meta form">
      <div class="form-wrap">
        <label for="geocoder">{{ $t("map.search") }}</label>
        <div id="geocoder" class="geocoder"></div>
      </div>
    </div>
    <div class="map-area">
      <div id="map"></div>
      <div v-show="listView" class="device-list">
        <!-- networks -->
        <div v-show="network_list.length > 0" class="selection">Du hast {{ mapSelectedNetworks.length }} Netze ausgewählt.</div>

        <!-- devices -->
        <div v-show="device_list.length > 0" class="selection">Du hast {{ mapSelectedDevices.length }} Bildschirme ausgewählt.</div>
        <div v-show="device_list.length < 1" class="selection">Keine Bildschirme in diesem Bereich verfügbar.</div>

        <div class="list">
          <!-- List of networks -->
          <div v-for="network in network_list" v-bind:key="network.uuid">
            <input
              type="checkbox"
              :id="network.uuid"
              :value="network.uuid"
              v-model="mapSelectedNetworks"
              @change="changeNetworkSelection"
              :selected="mapSelectedNetworks.includes(network.uuid)"
            />
            <label :for="network.uuid">
              <!--
              <div class="device-img">
                <div v-if="device.device_image">
                  <img :src="device.device_image" />
                </div>
              </div>
              -->
              <div class="device-icon">
                <div>
                  <img :src="require('./../assets/themes/' + _self.$theme + '/map_network.png')" />
                </div>
              </div>
              <div class="device-content">
                <h3>{{ network.title }}</h3>
                <p v-if="network.devices">{{ network.devices.length }}</p>
                <p v-if="network.description">{{ device.description }}</p>
              </div>
            </label>
          </div>

          <div v-for="device in device_list" v-bind:key="device.uuid">
            <input
              type="checkbox"
              :id="device.uuid"
              :value="device.uuid"
              v-model="mapSelectedDevices"
              @change="changeSelection"
              :selected="mapSelectedDevices.includes(device.uuid)"
            />
            <label :for="device.uuid">
              <!--
              <div class="device-img">
                <div v-if="device.device_image">
                  <img :src="device.device_image" />
                </div>
              </div>
              -->
              <div class="device-icon">
                <div>
                  <img :src="setImageBasedOnPrice(device.device_price)" />
                </div>
              </div>
              <div class="device-content">
                <h3>{{ device.name }}</h3>
                <p v-if="device.description">{{ device.description }}</p>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div v-show="editRadius" class="map-meta form">
      <div class="form-wrap" style="margin-top: 0">
        <label for="location">{{ $t("map.location") }}</label>
        <input v-model="campaign_location.loc" class="form-input" type="text" name="location" id="location" disabled />
      </div>
      <div class="form-wrap">
        <label for="radius">{{ $t("map.radius") }}</label>
        <input v-model="campaign_location.radius" v-on:change="radius_change" class="form-input" type="text" name="radius" id="radius" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../store";
import mapboxgl from "mapbox-gl";
import { Plugins } from "@capacitor/core";
const { Geolocation } = Plugins;
import MapboxCircle from "mapbox-gl-circle";
import mapbox_geo from "mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

export default {
  name: "MapView",
  props: [
    "clickRadius",
    "getPosition",
    "showCircle",
    "locationMarker",
    "campaignMarker",
    "initialZoom",
    "adresssearch",
    "editRadius",
    "getCampaign",
    "listView",
    "selectedDevices",
    "selectedNetworks",
  ],
  data() {
    return {
      devices: [],
      campaign: [],
      campaign_location: {
        loc: "Niederdorla",
        lat: 51.133333,
        lng: 10.416667,
        radius: 460000,
      },
      current_location: {
        lat: 51.133333,
        lng: 10.416667,
      },
      map: "",
      marker: "",
      radius_circle: "",
      loading: true,
      fetched_location: false,
      devices_geojson: {
        type: "FeatureCollection",
        features: [],
      },
      device_list: [],
      mapSelectedDevices: [],
      network_list: [],
      mapSelectedNetworks: [],
    };
  },
  methods: {
    async get_current_position() {
      try {
        const coordinates = await Geolocation.getCurrentPosition();
        mapboxgl.accessToken = "pk.eyJ1IjoibmlrNzM2IiwiYSI6ImNqZjJlbXB6NzB4cW8ycXBuOHdkcTk4eGsifQ.mUYmP9tL6F1qWnYOhhVQ9g";
        var that = this;

        that.campaign_location.lng = coordinates.coords.longitude;
        that.current_location.lng = coordinates.coords.longitude;
        that.campaign_location.lat = coordinates.coords.latitude;
        that.current_location.lat = coordinates.coords.latitude;
        axios
          .get(
            "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
              coordinates.coords.longitude +
              "," +
              coordinates.coords.latitude +
              ".json?access_token=" +
              mapboxgl.accessToken,
            {
              headers: { Authorization: store.getters.getToken },
            }
          )
          .then((response) => {
            that.campaign_location.loc = response.data.features[0].place_name;
            if (that.marker) {
              that.marker.remove();
            }
            if (that.myMarker) {
              that.marker = new mapboxgl.Marker({ color: that.$themeColor }).setLngLat([that.campaign_location.lng, that.campaign_location.lat]).addTo(that.map);
              that.$emit("locationChange", that.campaign_location);
            }
          });

        if (that.radius_circle) {
          that.radius_circle.remove();
        }

        if (that.showCircle) {
          that.radius_circle = new MapboxCircle({ lat: that.current_location.lat, lng: that.current_location.lng }, that.campaign_location.radius, {
            editable: false,
            fillColor: that.$themeColor,
            fillOpacity: 0.2,
            strokeColor: that.$themeContrastColor,
            strokeWeight: 2,
            strokeOpacity: 0.5,
          }).addTo(that.map)
        }

        that.map.flyTo({
          center: [that.current_location.lng, that.current_location.lat],
        });

        axios
          .get(
            "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
              that.current_location.lng +
              "," +
              that.current_location.lat +
              ".json?access_token=" +
              mapboxgl.accessToken,
            { headers: { Authorization: store.getters.getToken } }
          )
          .then((response) => {
            if (response.data.features[0]) {
              that.campaign_location.loc = response.data.features[0].place_name;
            }
          });

        this.fetched_location = true;
      } catch (e) {
        // no location
        console.log("no location");
      }
    },

    async show_map() {
      try {
        mapboxgl.accessToken = "pk.eyJ1IjoibmlrNzM2IiwiYSI6ImNqZjJlbXB6NzB4cW8ycXBuOHdkcTk4eGsifQ.mUYmP9tL6F1qWnYOhhVQ9g";
        this.map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: [this.current_location.lng, this.current_location.lat],
          zoom: this.initialZoom,
        });
      } catch (err) {
        console.log("map error", err);
      }

      var that = this;

      this.map.on("load", function () {
        var geocoder = new mapbox_geo({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
        });

        if (that.campaign.locations) {
          if (that.myMarker) {
            that.marker = new mapboxgl.Marker({ color: that.$themeColor })
              .setLngLat([parseFloat(that.campaign.locations[0].lng), parseFloat(that.campaign.locations[0].lat)])
              .addTo(that.map);
          }

          if (that.showCircle) {
            that.radius_circle = new MapboxCircle(
              { lat: parseFloat(that.campaign.locations[0].lat), lng: parseFloat(that.campaign.locations[0].lng) },
              that.campaign.locations[0].radius,
              {
                editable: false,
                fillColor: that.$themeColor,
                fillOpacity: 0.2,
                strokeColor: that.$themeContrastColor,
                strokeWeight: 2,
                strokeOpacity: 0.5,
              }
            ).addTo(that.map);
          }

          that.map.flyTo({
            center: [that.campaign.locations[0].lng, that.campaign.locations[0].lat],
          });

          that.map.resize();

          axios
            .get(
              "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
                that.campaign_location.lng +
                "," +
                that.campaign_location.lat +
                ".json?access_token=" +
                mapboxgl.accessToken,
              { headers: { Authorization: store.getters.getToken } }
            )
            .then((response) => {
              if (response.data.features[0]) {
                that.campaign_location.loc = response.data.features[0].place_name;
              }
            });
        } else {
          // set default location marker
          if (that.myMarker) {
            that.marker = new mapboxgl.Marker({ color: that.$themeColor })
              .setLngLat([parseFloat(that.campaign_location.lng), parseFloat(that.campaign_location.lat)])
              .addTo(that.map);
          }

          if (that.showCircle) {
            that.radius_circle = new MapboxCircle(
              { lat: parseFloat(that.campaign_location.lat), lng: parseFloat(that.campaign_location.lng) },
              that.campaign_location.radius,
              {
                editable: false,
                fillColor: that.$themeColor,
                fillOpacity: 0.2,
                strokeColor: that.$themeContrastColor,
                strokeWeight: 2,
                strokeOpacity: 0.5,
              }
            ).addTo(that.map);
          }
        }

        if (that.adresssearch) {
          document.getElementById("geocoder").appendChild(geocoder.onAdd(that.map));
          document.querySelector(".mapboxgl-ctrl-geocoder input").placeholder = "Suche";
        }

        if (that.locationMarker) {
          that.marker = new mapboxgl.Marker({ color: that.$themeColor }).setLngLat([that.current_location.lng, that.current_location.lat]).addTo(that.map);
        }

        geocoder.on("result", function (ev) {
          that.campaign_location.lng = ev.result.center[0];
          that.current_location.lng = ev.result.center[0];
          that.campaign_location.lat = ev.result.center[1];
          that.current_location.lat = ev.result.center[1];
          axios
            .get(
              "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
                ev.result.center[0] +
                "," +
                ev.result.center[1] +
                ".json?access_token=" +
                mapboxgl.accessToken,
              {
                headers: { Authorization: store.getters.getToken },
              }
            )
            .then((response) => {
              that.campaign_location.loc = response.data.features[0].place_name;
              that.marker.remove();
              if (that.myMarker) {
                that.marker = new mapboxgl.Marker({ color: that.$themeColor })
                  .setLngLat([that.campaign_location.lng, that.campaign_location.lat])
                  .addTo(that.map);
              }
            });

          that.radius_circle.remove();

          if (that.showCircle) {
            that.radius_circle = new MapboxCircle({ lat: that.current_location.lat, lng: that.current_location.lng }, that.campaign_location.radius, {
              editable: false,
              fillColor: that.$themeColor,
              fillOpacity: 0.2,
              strokeColor: that.$themeContrastColor,
              strokeWeight: 2,
              strokeOpacity: 0.5,
            }).addTo(that.map);
          }

          that.getDeviceList();
          that.$emit("locationChange", that.campaign_location);
          that.$emit("update:selectedDevices", that.mapSelectedDevices);
          that.$emit("update:selectedNetworks", that.mapSelectedNetworks);
        });

        let deviceData = {
          type: "FeatureCollection",
          features: that.devices_geojson.features,
        };

        that.map.addSource("devices", {
          type: "geojson",
          data: deviceData,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
        });

        that.map.addLayer({
          id: "unclustered-point",
          type: "symbol",
          source: "devices",
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-image": ["get", "icon"],
            "icon-size": 0.4,
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 1.25],
            "text-anchor": "top",
          },
        });

        that.map.loadImage(that.$t("asset.mapIcon"), (error, image) => {
          if (error) throw error;
          that.map.addImage("default_symbol", image);
        });

        that.map.loadImage(that.$t("asset.mapIconOffer"), (error, image) => {
          if (error) throw error;
          that.map.addImage("offer_symbol", image);
        });

        that.map.loadImage(that.$t("asset.mapIconPremium"), (error, image) => {
          if (error) throw error;
          that.map.addImage("premium_symbol", image);
        });

        that.map.addLayer({
          id: "clusters",
          type: "circle",
          source: "devices",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": ["step", ["get", "point_count"], that.$themeColor, 100, that.$themeColor, 750, that.$themeColor],
            "circle-radius": ["step", ["get", "point_count"], 25, 100, 30, 750, 35],
          },
        });

        that.map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "devices",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
        });

        that.map.resize();
        that.getDeviceList(true);
      });

      let popup = null;

      this.map.on("mouseover", "unclustered-point", (e) => {
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();
        //const description = e.features[0].properties.description;

        let description = e.features[0].properties.description;
        if (e.features[0].properties.description == null || e.features[0].properties.description == "null") {
          description = "";
        }
        const descriptionBreaks = description.replace(/\n/g, "<br />");

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup = new mapboxgl.Popup({
          offset: 15,
          className: "map-popup",
          closeButton: false,
        })
          .setLngLat(coordinates)
          .setHTML("<img src='" + e.features[0].properties.img_url + "'><strong>" + e.features[0].properties.name + "</strong><p>" + descriptionBreaks + "</p>")
          .addTo(that.map);
      });

      this.map.on("mouseout", "unclustered-point", () => {
        if (popup && popup.isOpen()) popup.remove();
      });

      if (this.clickRadius) {
        this.map.on("click", function (e) {
          that.campaign_location.lng = e.lngLat.lng;
          that.current_location.lng = e.lngLat.lng;
          that.campaign_location.lat = e.lngLat.lat;
          that.current_location.lat = e.lngLat.lat;

          that.getDeviceList();
          that.$emit("locationChange", that.campaign_location);
          that.$emit("update:selectedDevices", that.mapSelectedDevices);
          that.$emit("update:selectedNetworks", that.mapSelectedNetworks);

          axios
            .get("https://api.mapbox.com/geocoding/v5/mapbox.places/" + e.lngLat.lng + "," + e.lngLat.lat + ".json?access_token=" + mapboxgl.accessToken, {
              headers: { Authorization: store.getters.getToken },
            })
            .then((response) => {
              that.campaign_location.loc = response.data.features[0].place_name;
              if (that.marker) {
                that.marker.remove();
              }
              if (that.myMarker) {
                that.marker = new mapboxgl.Marker({ color: that.$themeColor })
                  .setLngLat([that.campaign_location.lng, that.campaign_location.lat])
                  .addTo(that.map);
              }
            });
          if (that.radius_circle) {
            that.radius_circle.remove();
          }
          that.radius_circle = new MapboxCircle({ lat: that.current_location.lat, lng: that.current_location.lng }, that.campaign_location.radius, {
            editable: false,
            fillColor: that.$themeColor,
            fillOpacity: 0.2,
            strokeColor: that.$themeContrastColor,
            strokeWeight: 2,
            strokeOpacity: 0.5,
          }).addTo(that.map);
        });
      }
    },

    radius_change() {
      this.radius_circle.remove();
      this.radius_circle = new MapboxCircle({ lat: this.campaign_location.lat, lng: this.campaign_location.lng }, this.campaign_location.radius, {
        editable: false,
        fillColor: this.$themeColor,
        fillOpacity: 0.2,
        strokeColor: this.$themeContrastColor,
        strokeWeight: 2,
        strokeOpacity: 0.5,
      }).addTo(this.map);

      this.$emit("radiusChange", this.campaign_location.radius);
      this.getDeviceList();
    },

    getDevices() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/devices/all", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.devices = response.data;
          var geojson = [];
          JSON.parse(response.data.features).forEach((device) => {
            geojson.push({
              type: "Feature",
              properties: device.properties,
              geometry: device.geometry,
            });
          });
          this.devices_geojson.features = geojson;
        });
    },

    getDeviceList(init) {
      axios
        .get(
          process.env.VUE_APP_BASE_API +
            "/devices?lat=" +
            this.campaign_location.lat +
            "&lng=" +
            this.campaign_location.lng +
            "&distance=" +
            this.campaign_location.radius +
            "&limit=1000",
          {
            headers: { Authorization: store.getters.getToken },
          }
        )
        .then((response) => {
          this.device_list = response.data.devices;

          // only visible devices
          this.device_list = this.device_list.filter((device) => device.visible_on_map == true);

          if (init && this.device_list.length > 0 && this.mapSelectedDevices.length > 0) {
            // if on init there are selected devices, check the correct id

            this.device_list.forEach((element) => {
              if (this.mapSelectedDevices.includes(element.device_id)) {
                // some hick hack cause device_id !== uuid and need to change it
                this.mapSelectedDevices = this.mapSelectedDevices.filter(function (item) {
                  return item !== String(element.device_id);
                });
                this.mapSelectedDevices.push(element.uuid);
              }
            });
          } else if (this.device_list.length > 0) {
            // push new devices
            this.mapSelectedDevices = [];

            this.device_list.forEach((element) => {
              if (element) {
                this.mapSelectedDevices.push(element.uuid);
                this.$emit("update:selectedDevices", this.mapSelectedDevices);
              }
            });
          } else {
            // of none selected

            this.mapSelectedDevices = [];
            this.$emit("update:selectedDevices", this.mapSelectedDevices);
          }
        });
    },

    getNetworkList(init) {
      axios
        .get(
          process.env.VUE_APP_BASE_API +
            "/networks?lat=" +
            this.campaign_location.lat +
            "&lng=" +
            this.campaign_location.lng +
            "&distance=" +
            this.campaign_location.radius +
            "&limit=1000",
          {
            headers: { Authorization: store.getters.getToken },
          }
        )
        .then((response) => {
          this.network_list = response.data.networks;

          // only visible networks
          this.network_list = this.network_list.filter((network) => network.visible_on_map == true);

          if (init && this.network_list.length > 0 && this.mapSelectedNetworks.length > 0) {
            // if on init there are selected networks, check the correct id

            this.network_list.forEach((element) => {
              if (this.mapSelectedNetworks.includes(element.network_id)) {
                // some hick hack cause network_id !== uuid and need to change it
                this.mapSelectedNetworks = this.mapSelectedNetworks.filter(function (item) {
                  return item !== String(element.network_id);
                });
                this.mapSelectedNetworks.push(element.uuid);
              }
            });
          } else if (this.network_list.length > 0) {
            // push new networks
            this.mapSelectedNetworks = [];

            this.network_list.forEach((element) => {
              if (element) {
                this.mapSelectedNetworks.push(element.uuid);
                this.$emit("update:selectedNetworks", this.mapSelectedNetworks);
              }
            });
          } else {
            // of none selected

            this.mapSelectedNetworks = [];
            this.$emit("update:selectedNetworks", this.mapSelectedNetworks);
          }
        });
    },

    get_campaign() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid, { headers: { Authorization: store.getters.getToken } })
        .then((response) => {
          this.campaign = response.data.campaign;

          this.campaign_location.lat = parseFloat(response.data.campaign.locations[0].lat);
          this.campaign_location.lng = parseFloat(response.data.campaign.locations[0].lng);
          this.campaign_location.radius = response.data.campaign.locations[0].radius;
          this.mapSelectedDevices = response.data.campaign.devices;

          this.loading = false;
        });
    },

    rerenderMap() {
      this.map.resize();
    },

    changeSelection() {
      this.$emit("update:selectedDevices", this.mapSelectedDevices);
      this.$emit("update:selectedNetworks", this.mapSelectedNetworks);
    },

    setImageBasedOnPrice(price) {
      if (price < 0.0019) {
        return "/map_offer.png";
      } else if (price > 0.0021) {
        return "/map_premium.png";
      } else {
        return "/map.png";
      }
    },
  },
  mounted() {
    if (this.getCampaign) {
      this.get_campaign();
    }

    this.getDevices();

    if (this.getPosition) {
      setTimeout(() => {
        this.get_current_position();
      }, 100);
    }

    setTimeout(() => {
      this.show_map();
    }, 1000);
  },
};
</script>

<style lang="scss">
.mapboxgl-ctrl-group button {
  width: 50px;
  height: 50px;
  background-size: 30px 30px;
}

.map-wrap {
  position: relative;
  height: auto !important;

  #map {
    width: 100%;
    height: 500px;
  }

  .map-meta {
    width: 100%;
    background: #f9f9f9;
    padding: 20px;
    position: relative;
    z-index: 200;

    @media (min-width: 768px) {
      display: flex;
      align-items: baseline;

      .form-wrap {
        width: 100%;
        margin-top: 0 !important;
        margin-bottom: 10px !important;

        &:nth-of-type(2) {
          margin-left: 20px;
        }
      }
    }
  }
}

.mapboxgl-popup-content {
  padding: 0;

  button {
    background: white;
    color: black;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    font-size: 20px;
    top: 5px;
    right: 5px;
    line-height: 1;
    display: flex;
    align-items: baseline;
    justify-content: center;
    line-height: 1.5;

    &:hover {
      background: lightgray;
    }
  }

  p,
  strong {
    padding: 10px;
  }

  strong {
    display: block;
    padding-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    margin-top: 0 !important;
  }

  p {
    font-size: 12px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    line-height: 1.3;
  }

  img {
    max-height: 250px;
  }
}

#geocoder {
  width: 100%;

  .mapboxgl-ctrl-geocoder {
    width: 100%;
    max-width: 100%;

    input {
      display: block;
      width: 100%;
      padding: 14px;
      line-height: 1.5;
      background: #fff;
      border: 1px solid #d7e3e6;
      -webkit-box-shadow: inset 0 0 3px 0 rgba(215, 227, 230, 0.4);
      box-shadow: inset 0 0 3px 0 rgba(215, 227, 230, 0.4);
      border-radius: 4px;
      font-size: 16px;
    }

    .geocoder-pin-right {
      display: none;
    }
  }

  .suggestions li {
    padding-right: 0 !important;
    width: 100%;

    &::after {
      display: none !important;
    }
  }
}

@media (min-width: 1024px) {
  .fifthy {
    display: flex;
    > div {
      width: 50%;

      &:nth-of-type(1) {
        margin-right: 10px;
      }
    }
  }
}

.map-area {
  position: relative;
  overflow: hidden;

  .device-list {
    margin: 10px 0;
    padding: 10px 0;

    .list {
      display: flex;
      overflow-x: scroll;
      > div {
        margin: 5px;
      }
    }

    @media (min-width: 1024px) {
      position: absolute;
      width: 330px;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      z-index: 100;
      background: #f9f9f9;

      margin: 0;

      box-shadow: -5px 0px 19px 7px #8686868f;

      .list {
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: initial;
        padding: 5px 10px;
        height: 100%;
      }
    }

    input {
      display: none;
    }

    .device-img {
      width: 100%;

      img {
        border-radius: 5px;
        overflow: hidden;
      }

      @media (min-width: 1024px) {
        width: 100px;
        padding-right: 10px;
      }
    }

    .device-icon {
      max-width: 40px;
      padding: 0px 5px;
      margin-right: 10px;
    }

    .device-content {
      width: auto;

      @media (min-width: 1024px) {
        width: 200px;
      }
    }

    label {
      display: flex;
      align-items: center;
      padding: 10px 5px;
      outline: 1px solid #b3b3b3;
      border-radius: 5px;

      flex-direction: column;
      width: 150px;
      height: 100%;

      @media (min-width: 1024px) {
        flex-direction: row;
        width: 100%;
        height: auto;
      }

      &:hover {
        cursor: pointer;
      }
    }

    input:checked + label {
      background: var(--ci-color-opacity);
    }

    h3 {
      font-size: 0.9rem !important;
      margin-top: 10px !important;

      @media (min-width: 1024px) {
        margin-top: 0 !important;
      }
    }
  }
}

.selection {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 10px;
  padding-left: 5px;

  @media (min-width: 1024px) {
    padding-left: 15px;
  }
}

.mapboxgl-marker {
  background-size: 100% 100%;
}
</style>
